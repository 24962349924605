import { EVENT_TYPES_ICON } from '../assets/images/iconfont/event_type'
import { VIDEO_EVENT_TYPES_ICON } from '../assets/images/iconfont/video_event_type'

/**
 * 行业类型
 * @type
 */
export const INDUSTRY_TYPE = {
  高速公路: { label: '高速公路', value: 'highway' },
  国省干线: { label: '国省干线', value: 'road' },
  水运: { label: '水运', value: 'water' },
  客运: { label: '客运', value: 'passenger' },
  事件: { label: '事件', value: 'event' },
  菜单: { label: '菜单', value: 'menu' }
}

/**
 * 资源类型
 * @type
 */
export const RESOURCE_TYPE = {
  高速公路路线: { label: '路线', value: 'highway_route' },
  高速公路区域: { label: '区域', value: 'highway_area' },
  高速公路收费所: { label: '收费所', value: 'highway_department' },
  国省干线路线: { label: '路线', value: 'road_route' },
  国省干线区域: { label: '公路局', value: 'road_area' },
  水运部门: { label: '水运', value: 'water_department' },
  客运区域: { label: '区域', value: 'passenger_area' },
  交通事件: { label: '交通事件', value: 'event_traffic' },
  视频事件: { label: '视频事件', value: 'event_camera' },
  智能分析: { label: '智能分析', value: 'menu_analyse' },
  点位分析: { label: '点位分析', value: 'menu_point' },
  视频轮巡: { label: '视频轮巡', value: 'camera_tour' },
  视频在线数统计: { label: '视频在线数统计', value: 'statistics' },
}

/**
 * 部门查询参数
 * @type
 */
export const DEPARTMENT_QUERY_TYPE = {
  甘肃省高速公路局: { label: '甘肃省高速公路局', value: '甘肃省高速公路局' },
  甘肃省公路局: { label: '甘肃省公路局', value: '甘肃省公路局' },
  甘肃省水运局: { label: '甘肃省水运事业发展中心', value: '甘肃省水运事业发展中心' },
  道路运输事业发展中心: { label: '道路运输事业发展中心', value: '道路运输事业发展中心' },
  全部: { label: '全部', value: '全部' }
}

/**
 * 事件类型
 图标分类:
   1. 道路施工
   2. 拥堵
   3. 交通管制
   4. 交通事故
   5. 其他
   6. 天气
   7. 异常停车,行驶车辆异常
   8. 路面异常,基建设施异常,道路险情
 */
export const EVENT_TYPE = {
  道路施工: { label: '道路施工', value: '122001' },
  道路险情: { label: '道路险情', value: '122002' },
  拥堵: { label: '拥堵', value: '122003' },
  交通管制: { label: '交通管制', value: '122005' },
  交通事故: { label: '交通事故', value: '122006' },
  其他: { label: '其他', value: '122007' },
  天气: { label: '天气', value: '122008' },
  行驶车辆异常: { label: '行驶车辆异常', value: '122009' },
  路面异常: { label: '路面异常', value: '122010' },
  基建设施异常: { label: '基建设施异常', value: '122011' },
  异常停车: { label: '异常停车', value: '122012' },
  properties: {
    122001: { label: '道路施工', value: '122001', src: EVENT_TYPES_ICON.WORK },
    122002: { label: '道路险情', value: '122002', src: EVENT_TYPES_ICON.ROAD_ABNORMAL },
    122003: { label: '拥堵', value: '122003', src: EVENT_TYPES_ICON.CONGESTION },
    122005: { label: '交通管制', value: '122005', src: EVENT_TYPES_ICON.TRAFFIC_CONTROL },
    122006: { label: '交通事故', value: '122006', src: EVENT_TYPES_ICON.ACCIDENT },
    122007: { label: '其他', value: '122007', src: EVENT_TYPES_ICON.OTHERS },
    122008: { label: '天气', value: '122008', src: EVENT_TYPES_ICON.WEATHER },
    122009: { label: '行驶车辆异常', value: '122009', src: EVENT_TYPES_ICON.VEHICLE_ABNORMAL },
    122010: { label: '路面异常', value: '122010', src: EVENT_TYPES_ICON.ROAD_ABNORMAL },
    122011: { label: '基建设施异常', value: '122011', src: EVENT_TYPES_ICON.ROAD_ABNORMAL },
    122012: { label: '异常停车', value: '122012', src: EVENT_TYPES_ICON.VEHICLE_ABNORMAL },
  }
}

/*
视频事件相关枚举
 */
export const VIDEO_EVENT_ENUM = {
  alertType: {
    A: '交通拥堵',
    B: '车辆停驶',
    C: '遗弃/抛洒物',
    D: '行人',
    E: '车辆慢行',
    F: '烟雾事件',
    G: '道路施工',
    H: '车辆逆行',
    I: '驶入避险车道',
    J: '火灾事件',
    K: '能见度低/雨雪/沙尘',
    L: '非机动车',
    M: '占用应急车道',
    N: '两客一危',
    O: '事故事件'
  },
  img: {
    A: VIDEO_EVENT_TYPES_ICON.JTYD,
    B: VIDEO_EVENT_TYPES_ICON.CLTS,
    C: VIDEO_EVENT_TYPES_ICON.YQ,
    D: VIDEO_EVENT_TYPES_ICON.XR,
    E: VIDEO_EVENT_TYPES_ICON.CLMX,
    F: VIDEO_EVENT_TYPES_ICON.YWSJ,
    G: VIDEO_EVENT_TYPES_ICON.DLSG,
    H: VIDEO_EVENT_TYPES_ICON.CLNX,
    I: VIDEO_EVENT_TYPES_ICON.BXCD,
    J: VIDEO_EVENT_TYPES_ICON.HZ,
    K: VIDEO_EVENT_TYPES_ICON.NJDD,
    L: VIDEO_EVENT_TYPES_ICON.MTC,
    M: VIDEO_EVENT_TYPES_ICON.YJCD,
    N: VIDEO_EVENT_TYPES_ICON.LKYW,
    O: VIDEO_EVENT_TYPES_ICON.SGSJ
  },
  laneDir: {
    0: '未知',
    1: '上行',
    2: '下行',
    3: '上下行'
  },
  laneType: {
    0: '行车道',
    1: '应急车道',
    2: '超车道',
    3: '车道外',
    4: '避险车道',
  },
  type: {
    A: '汽车',
    B: '行人',
    C: '客车',
    D: '卡车',
    E: '摩托车',
    F: '自行车',
    G: '危化品车'
  },
  direction: {
    up: '上行',
    Up: '上行',
    down: '下行',
    Down: '下行'
  }
}

/**
 * 事件状态
 */
export const EVENT_STATUS = {
  已结束: 0,
  进行中: 1,
  properties: {
    0: {label: '已结束'},
    1: {label: '进行中'}
  }
}

/**
 * 视频码流类型
 */
export const STREAM_TYPE = {
  低码流: 0,
  高码流: 1,
  properties: {
    0: {label: '低码流'},
    1: {label: '高码流'}
  }
}

/**
 * 事件报警状态
 */
export const EVENT_ALARM_STATUS = {
  已确认: 0,
  未确认: 1,
  properties: {
    0: {label: '已确认'},
    1: {label: '未确认'}
  }
}

/**
 * 摄像机类型
 */
export const CAMERA_TYPE = {
  道路沿线: 1,
  桥梁: 2,
  隧道: 3,
  收费广场: 4,
  收费站: 5,
  服务区: 6,
  ETC门架: 7,
  移动视频源: 8,
  properties: {
    1: {label: '道路沿线'},
    2: {label: '桥梁'},
    3: {label: '隧道'},
    4: {label: '收费站'},
    5: {label: '收费站'},
    6: {label: '服务区'},
    7: {label: 'ETC门架'},
    8: {label: '移动视频源'}
  }
}

/**
 * 高速公路
 * @type
 */
export const HIGHWAY_ENUM = {
  G1816乌玛高速: { label: '', value: '', code: '' },
  G2012定武高速: { label: '', value: '' },
  G22青兰高速: { label: '', value: '' },
  G30连霍高速: { label: '', value: '' },
  G2201兰州南绕城高速: { label: '', value: '' },
  G3011柳格高速: { label: '', value: '' },
  G3017武金高速: { label: '', value: '' },
  G6京藏高速: { label: '', value: '' },
  'G0611张掖-汶川': { label: '', value: '' },
  'G69银川-白色': { label: '', value: '' },
  G7京新高速: { label: '', value: '' },
  G70福银高速: { label: '', value: '' },
  G7011十天高速: { label: '', value: '' },
  G75兰海高速: { label: '', value: '' },
  'G85银川-昆明': { label: '', value: '' },
  G8513平绵高速: { label: '', value: '' },
  S13尹中高速: { label: '', value: '' },
  S14陇渭高速: { label: '', value: '' },
  S17金永高速: { label: '', value: '' },
  '沿黄一级（省级）': { label: '', value: '' },
  兰永高速: { label: '', value: '' },
  G215柳敦高速: { label: '', value: '' },
  G568酒航高速: { label: '', value: '' },
  G316两徽高速: { label: '', value: '' },
  properties: {
    G1816: { label: 'G1816乌玛高速', value: '乌玛高速', code: 'G1816' },
    G2012: { label: 'G2012定武高速', value: '定武高速', code: 'G2012' },
    G22: { label: 'G22青兰高速', value: '青兰高速', code: 'G22' },
    G30: { label: 'G30连霍高速', value: '连霍高速', code: 'G30' },
    G2201: { label: 'G2201兰州南绕城高速', value: '兰州南绕城高速', code: 'G2201' },
    G3011: { label: 'G3011柳格高速', value: '柳格高速', code: 'G3011' },
    G3017: { label: 'G3017武金高速', value: '武金高速', code: 'G3017' },
    G6: { label: 'G6京藏高速', value: '京藏高速', code: 'G6' },
    G0611: { label: 'G0611张掖-汶川', value: '张掖-汶川', code: 'G0611' },
    G69: { label: 'G69银川-白色', value: '银川-白色', code: 'G69' },
    G7: { label: 'G7京新高速', value: '京新高速', code: 'G7' },
    G70: { label: 'G70福银高速', value: '福银高速', code: 'G70' },
    G7011: { label: 'G7011十天高速', value: '十天高速', code: 'G7011' },
    G75: { label: 'G75兰海高速', value: '兰海高速', code: 'G75' },
    G85: { label: 'G85银川-昆明', value: '银川-昆明', code: 'G85' },
    G8513: { label: 'G8513平绵高速', value: '平绵高速', code: 'G8513' },
    S13: { label: 'S13尹中高速', value: '尹中高速', code: 'S13' },
    S14: { label: 'S14陇渭高速', value: '陇渭高速', code: 'S14' },
    S17: { label: 'S17金永高速', value: '金永高速', code: 'S17' },
    YH: { label: '沿黄一级（省级）', value: '沿黄一级（省级）', code: 'YH' },
    YL: { label: '兰永高速', value: '兰永高速', code: 'YL' },
    G215: { label: 'G215柳敦高速', value: '柳敦高速', code: 'G215' },
    G568: { label: 'G568酒航高速', value: '酒航高速', code: 'G568' },
    G316: { label: 'G316两徽高速', value: '两徽高速', code: 'G316' }
  }
}

/**
 * 重点路段
 * @type {{}}
 */
export const IMPORTANT_ROAD_SECTION = {
  iceProneSection: {
    key: 'iceProneSection',
    name: '道路易结冰路段',
    // 选择框中的图片
    imgSelect: require("../assets/images/importantRoad/roadType/icon_zdld_jb.png"),
    // 列表中的图片
    imgList: require("../assets/images/importantRoad/roadTypeCount/bg_jb.png"),
    selected: true,
    style: {
      // 基础色
      baseColor: 'rgba(0, 238, 255, 0.65)',
      // 背景色
      background: 'linear-gradient(180deg, rgba(93, 233, 255, 0.3) 0%, rgba(64, 219, 243, 0.74) 71%, #29CFE9 100%)',
      // 遮罩色
      modalColor: '#ABEBFF'
    }
  },
  longDownhillSection: {
    key: 'longDownhillSection',
    name: '长大下坡路段',
    imgSelect: require("../assets/images/importantRoad/roadType/icon_zdld_xp.png"),
    imgList: require("../assets/images/importantRoad/roadTypeCount/bg_xp.png"),
    selected: true,
    style: {
      baseColor: '#2FBC34',
      background: 'linear-gradient(180deg, rgba(40, 200, 45, 0.3) 0%, rgba(40, 200, 45, 0.74) 71%, #28C82D 100%)',
      modalColor: '#A8FFAB'
    }
  },
  congestionSection: {
    key: 'congestionSection',
    name: '易拥堵路段',
    imgSelect: require("../assets/images/importantRoad/roadType/icon_zdld_yd.png"),
    imgList: require("../assets/images/importantRoad/roadTypeCount/bg_yd.png"),
    selected: true,
    style: {
      baseColor: '#E38B2A',
      background: 'linear-gradient(180deg, rgba(233, 143, 41, 0.3) 0%, rgba(233, 143, 41, 0.74) 71%, #E98F29 100%)',
      modalColor: '#FFE7CC'
    }
  },
  trafficAccidentSection: {
    key: 'trafficAccidentSection',
    name: '易发生事故路段',
    imgSelect: require("../assets/images/importantRoad/roadType/icon_zdld_sg.png"),
    imgList: require("../assets/images/importantRoad/roadTypeCount/bg_sg.png"),
    selected: true,
    style: {
      baseColor: '#E42A8E',
      background: 'linear-gradient(180deg, rgba(255, 93, 179, 0.3) 0%, rgba(243, 64, 148, 0.74) 71%, #E9298F 100%)',
      modalColor: '#FFDBEF'
    }
  },
  lowVisibilitySection: {
    key: 'lowVisibilitySection',
    name: '易出现雾霾/大雾路段',
    imgSelect: require("../assets/images/importantRoad/roadType/icon_zdld_wm.png"),
    imgList: require("../assets/images/importantRoad/roadTypeCount/bg_wm.png"),
    selected: true,
    style: {
      baseColor: '#969697',
      background: 'linear-gradient(180deg, rgba(151, 151, 151, 0.3) 0%, rgba(155, 155, 155, 0.74) 71%, #989898 100%)',
      modalColor: '#D9D9D9'
    }
  },
  mountainSlideSection: {
    key: 'mountainSlideSection',
    name: '易山体滑坡路段',
    imgSelect: require("../assets/images/importantRoad/roadType/icon_zdld_hp.png"),
    imgList: require("../assets/images/importantRoad/roadTypeCount/bg_hp.png"),
    selected: true,
    style: {
      baseColor: '#ECD727',
      background: 'linear-gradient(180deg, rgba(239, 218, 38, 0.3) 0%, rgba(239, 218, 38, 0.74) 71%, #EFDA26 100%)',
      modalColor: '#FFF8C2'
    }
  },
  bigBridgeSection: {
    key: 'bigBridgeSection',
    name: '特大桥路段',
    imgSelect: require("../assets/images/importantRoad/roadType/icon_zdld_dq.png"),
    imgList: require("../assets/images/importantRoad/roadTypeCount/bg_dq.png"),
    selected: true,
    style: {
      baseColor: '#8F29E8',
      background: 'linear-gradient(180deg, rgba(144, 41, 233, 0.3) 0%, rgba(144, 41, 233, 0.74) 71%, #9029E9 100%)',
      modalColor: '#E5C7FF'
    }
  }
}
