// 四角边框样式
import BorderLeftTop from './left_top.png'
import BorderLeftBottom from './left_bottom.png'
import BorderRightTop from './right_top.png'
import BorderRightBottom from './right_bottom.png'
import ArrowLeft from './arrow-left.png'
import ArrowRight from './arrow-right.png'

const Corner = {
    BorderLeftTop,
    BorderLeftBottom,
    BorderRightTop,
    BorderRightBottom,
    ArrowLeft,
    ArrowRight
}


export default Corner
