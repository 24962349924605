// 事件图标
import WORK from './icon_events_work.png'
import CONGESTION from './icon_events_congestion.png'
import TRAFFIC_CONTROL from './icon_events_traffic_control.png'
import ACCIDENT from './icon_events_accident.png'
import OTHERS from './icon_events_others.png'
import WEATHER from './icon_events_weather.png'
import VEHICLE_ABNORMAL from './icon_events_vehicle_abnormal.png'
import ROAD_ABNORMAL from './icon_events_road_abnormal.png'


export const EVENT_TYPES_ICON = {
    WORK: WORK,
    CONGESTION: CONGESTION,
    TRAFFIC_CONTROL: TRAFFIC_CONTROL,
    ACCIDENT: ACCIDENT,
    OTHERS: OTHERS,
    WEATHER: WEATHER,
    VEHICLE_ABNORMAL: VEHICLE_ABNORMAL,
    ROAD_ABNORMAL: ROAD_ABNORMAL,
}



































