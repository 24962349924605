// 视频事件图标
import BXCD from './icon_events_bxcd_32.png'
import CLMX from './icon_events_clmx_32.png'
import CLNX from './icon_events_clnx_32.png'
import CLTS from './icon_events_clts_32.png'
import DLSG from './icon_events_dlsg_32.png'
import HZ from './icon_events_hz_32.png'
import JTYD from './icon_events_jtyd_32.png'
import LKYW from './icon_events_lkyw_32.png'
import MTC from './icon_events_mtc_32.png'
import NJDD from './icon_events_njdd_32.png'
import XR from './icon_events_xr_32.png'
import YJCD from './icon_events_yjcd_32.png'
import YQ from './icon_events_yq_32.png'
import YWSJ from './icon_events_ywsj_32.png'
import SGSJ from './icon_events_sgsj_32.png'


export const VIDEO_EVENT_TYPES_ICON = {
    BXCD,
    CLMX,
    CLNX,
    CLTS,
    DLSG,
    HZ,
    JTYD,
    LKYW,
    MTC,
    NJDD,
    XR,
    YJCD,
    YQ,
    YWSJ,
    SGSJ
}



































